import React, { useEffect } from "react";

export const Services = (props) => {

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Products</h2>
        </div>
        {/* <div className="row section-button">
          {props.data ? props.data.category.map((d, i) => (

            < button key={`${d.title}-${i}`} className="btn-custom btn-lg btn-services-type" type="submit">{d.title}</button>
          )) : "loading"}
        </div> */}
        <div className="row" id="services-odd">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/portfolio/vending2.png" className="img-responsive center-block" id="img-services" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>MyGraPari</h2>
              <p>Pada dasarnya MyGraPARI adalah berbentuk vending machine yang bersifat digital
                dan dapat diakses secara mandiri (self-service) oleh pelanggan baik di GraPARI (Pusat
                Layanan Telkomsel) maupun area publik.</p>
              <p>Lewat layanan ini, pelanggan akan mendapatkan layanan fisik dan non fisik yang
                berupa informasi, permintaan, pengaduhan keluhan dan pembelian produk Telkomsel
                yang selama ini dilakukan melalui gerai fisik Grapari di Indonesia. Nur Lesmana,
                Officer Aps Web Management Telkomsel mengatakan bahwa MyGraPARI menjadi
                produk digital customer care pertama di Asia Tenggara yang diluncurkan perusahaan
                telko. Ia juga bilang bahwa kehadiran mesin MyGrapari adalah jawaban atas
                permasalahan yang sering dialami pelanggan di Grapari, yaitu lama mengantri.</p>
            </div>
          </div>
        </div>
        <div className="row" id="services-even">
          <div className="col-xs-12 col-md-6">
            <div className="about-text-right">
              <h2>Buka Rekening</h2>
              <p>Anjungan layanan mandiri NON-Finansial yang memiliki fungsi utama untuk melayani
                nasabah yang ingin membuka akun tabungan baru, mengganti kartu debit yang
                rusak, mencetak histori transaksi periode waktu tertentu. Fungsi lain seperti
                mengaktifkan/menon-aktifkan Internet banking dan layanan non-finansial lain sangat
                mungkin ditambahkan pada mesin ini.</p>
              <p>DIGITAL SIGNATE yang menampilkan produk unggulan yang hendak diperkenalkan
                ke masyarakat yang dilengkapi dengan Content Management yang memungkinkan
                pengaturan jadwal penayangan yang sesuai dengan yang dijadwalkan. </p>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/portfolio/Group-442.png" className="img-responsive center-block" id="img-services" alt="" />{" "}
          </div>
        </div>
        <div className="row" id="services-odd">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/portfolio/Group-443.png" className="img-responsive center-block" id="img-services" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Passbook Printer</h2>
              <p>Anjungan layanan mandiri NON-Finansial yang memiliki fungsi utama melayani
                nasabah yang ingin mencetak transaksi ke buku tabungan mereka atau mencetak
                histori transaksi periode waktu tertentu.
              </p>
              <p>DIGITAL SIGNATE yang menampilkan produk unggulan yang hendak diperkenalkan
                ke masyarakat yang dilengkapi dengan Content Management yang memungkinkan
                pengaturan jadwal penayangan yang sesuai dengan yang dijadwalkan. Sering
                digunnakan sebagai mesin untuk mencetak tiket atau kupon dari acara & kegiatan
                yang diselenggarakan pihak bank</p>
            </div>
          </div>
        </div>
        <div className="row" id="services-even">
          <div className="col-xs-12 col-md-6">
            <div className="about-text-right">
              <h2>MESIN SELF SERVICE FOREIGN EXCHANGE</h2>
              <p>Mesin penukar mata uang asing ke rupiah yang dapat mengenali 100 jenis mata uang yang dipilih. Dapat mengeluarkan kembali 120 lembar uang yang telah dimasukkan ketika ada pembatalan transaksi. Mesin ini memiliki kapasitas untuk menampung 1.000 lembar uang asing.
              </p>
              <p>
                *Spesifikasi dapat berubah sesuai dengan perkembangan teknologi atau kebutuhan customer
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/portfolio/Group-440.png" className="img-responsive center-block" id="img-services" alt="" />{" "}
          </div>
        </div>
        <div className="row" id="services-odd">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/portfolio/Group-433.png" className="img-responsive center-block" id="img-services" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>TSA - Trilogi Smart Access</h2>
              <p>Solusi perusahaan untuk dapat memiliki kontrol akses bertingkat yang baik untuk bisnis anda, yang juga memastikan bahwa karyawan dan pengunjungan dapat memperoleh akses ke area yang dibutuhkan pada waktu yang tepat dengan hambatan minimal sehingga kenyamanan, keamanan, dan stabilitas dapat dicapai secara bersamaan.
              </p>
              <p>Sistem ini juga dilengkapi dengan dashboard dan monitoring di mana manajemen dapat melakukan pemantauan area-area yang sering dikunjungi, otorisasi pertemuan tamu yang berkunjung dengan berbagai macam keperluan, dan otentisitas pengunjung yang dapat dilakukan dengan alat baca eKTP untuk validasi pengunjung.
              </p>
              <p>Sistem ini dapat juga di integrasikan dengan sistem lainnya
              </p>
              {/* <ul>
                <li>Face Recognition</li>
                <li>NFC Reader</li>
                <li>Temperature Measurement</li>
                <li>Recognize User with Mask</li>
                <li>Attendance</li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
