import React from "react";
import { IoMdClose } from "react-icons/io";

export const Policy = (props) => {
  return (
    <div
      className="modal"
      tabIndex="-1"
      style={{ display: "block", zIndex: 1050 }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Kebijakan ISO</h5>
            <button
              type="button"
              className="btn-close"
              onClick={props.closeModal}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <IoMdClose />
            </button>
          </div>
          <div className="modal-body">
            <ul className="policy-list">
              {props.data
                ? props.data.map((d, i) => <li key={`${d}-${i}`}> {d.item}</li>)
                : "loading"}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
